/*
This file should import nothing because it may be used outside the platform.
 */

/**
 * {@link Element} attribute that can be used to uniquely identify Elements for purposes outside
 * of styling (e.g. analytics, e2e testing, etc.). There should only ever be one Element that
 * has any given EverId.
 */
const EVERID_ATTRIBUTE = "data-everid";

/**
 * Object containing all EverIds. EverIds are used to uniquely identify Elements for purposes
 * outside of styling (e.g. analytics, e2e testing, etc.). There should only ever be one Element
 * that has any given EverId.
 *
 * All properties/constants defined in this object should be in alphabetical order.
 */
export const EVERID = {
    ANALYTICS_PAGE: {
        PROJECT_SIZES: {
            HISTORICAL_SIZE_CHART: "analytics-historical-size-chart",
        },
        SIDEBAR: "analytics-sidebar",
    },
    ASSIGNMENT_PAGE: {
        ASSIGN_WIZARD_SUBMIT_BUTTON: "assign-wizard-submit-button",
        REASSIGN_WIZARD_SUBMIT_BUTTON: "reassign-wizard-submit-button",
    },
    DEPOSITION_PAGE: {
        CREATE_BUTTON: "deposition-wizard-create-button",
        TRANSCRIPT_ANALYZER_GENERATE_BUTTON: "transcript-analyzer-generate-button",
        TRANSCRIPT_FACT_EXTRACTION_GENERATE_BUTTON: "transcript-fact-extraction-generate-button",
    },
    DRAFT_PAGE: {
        CREATE_BUTTON: "draft-create-button",
    },
    HOME_PAGE: {
        FOLDERS: "homepage-folders-tab",
        NEW_FOLDER_BUTTON: "homepage-new-folder-button",
        TABS: "homepage-tabs",
    },
    LEGAL_HOLD_PAGE: {
        DATA_PRESERVATION_EXPORT_REPORT_BUTTON: "data-preservation-export-report-button",
        DATA_PRESERVATION_REACTIVATE_ALL_BUTTON: "data-preservation-reactivate-all-button",
        DATA_PRESERVATION_REACTIVATE_BUTTON: "data-preservation-reactivate-button",
        DATA_PRESERVATION_RELEASE_ALL_BUTTON: "data-preservation-release-all-button",
        DATA_PRESERVATION_RELEASE_BUTTON: "data-preservation-release-button",
        DIRECTORY_ADD_TO_EXISTING: "legal-hold-directory-add-to-existing",
        DIRECTORY_ADD_TO_NEW: "legal-hold-directory-add-to-new",
        DIRECTORY_EXPORT_REPORT_BUTTON: "legal-hold-directory-export-report-button",
        DIRECTORY_ISSUE_EXISTING: "legal-hold-directory-issue-existing",
        DIRECTORY_ISSUE_NEW: "legal-hold-directory-issue-new",
        DIRECTORY_RELEASE_FROM_ALL_BUTTON: "legal-hold-directory-release-from-all-button",
        ESCALATE_BUTTON: "legal-hold-escalate-button",
        REACTIVATE_ALL_BUTTON: "legal-hold-reactivate-all-button",
        REACTIVATE_BUTTON: "legal-hold-reactivate-button",
        REISSUE_BUTTON: "legal-hold-reissue-button",
        RELEASE_ALL_BUTTON: "legal-hold-release-all-button",
        RELEASE_BUTTON: "legal-hold-release-button",
        RENOTIFY_BUTTON: "legal-hold-renotify-button",
    },
    RESULTS_PAGE: {
        BATCH_ADD_REMOVE_FROM_PROJECT: "results-batch-add-remove-from-project",
        BATCH_ASSIGN: "results-batch-assign",
        BATCH_COPY_WORK_PRODUCT: "results-batch-copy-work-product",
        BATCH_DELETE_FROM_DB: "results-batch-delete-from-db",
        BATCH_MODIFY: "results-batch-modify",
        BATCH_OTHER_BATES: "results-batch-other-bates",
        BATCH_REDACT: "results-batch-redact",
        BATCH_REPROCESS: "results-batch-reprocess",
    },
    REVIEW_PAGE: {
        GROUPING_ASSISTANT_BUTTON: "review-grouping-assistant-button",
        GROUPING_ATTACHMENTS_BUTTON: "review-grouping-attachments-button",
        GROUPING_CHAT_CONVERSATION_BUTTON: "review-grouping-chat-conversation-button",
        GROUPING_CLUSTERING_BUTTON: "review-grouping-clustering-button",
        GROUPING_DOC_REFERENCES_BUTTON: "review-grouping-doc-references-button",
        GROUPING_DUPLICATES_BUTTON: "review-grouping-duplicates-button",
        GROUPING_THREADING_BUTTON: "review-grouping-threading-button",
        GROUPING_UNITIZATION_BUTTON: "review-grouping-unitization-button",
        GROUPING_VERSION_BUTTON: "review-grouping-version-button",
    },
    SEARCH_PAGE: {
        SEARCH_BUILDER: "search-builder",
        SEARCH_FRAME: "search-frame",
        SEARCH_NAME_CONTAINER: "search-name-container",
    },
    SEARCH_TERM_REPORT_PAGE: {
        ADD_SEARCH_TERM: "SearchTermReport-add-term",
        SEARCH_TERM_REPORT_LIST: "search-term-report-list",
    },
    SUPERUSER_PAGE: {
        SIDEBAR: "superuser-sidebar",
    },
    UPLOAD_PAGE: {
        CLOUD_ASANA_BUTTON: "cloud-upload-asana-button",
        CLOUD_BOX_BUTTON: "cloud-upload-box-button",
        CLOUD_DIRECT_LINK_BUTTON: "cloud-upload-direct-link-button",
        CLOUD_DROPBOX_BUTTON: "cloud-upload-dropbox-button",
        CLOUD_GOOGLE_DRIVE_BUTTON: "cloud-upload-google-drive-button",
        CLOUD_GOOGLE_VAULT_BUTTON: "cloud-upload-google-vault-button",
        CLOUD_JIRA_BUTTON: "cloud-upload-jira-button",
        CLOUD_OFFICE_365_BUTTON: "cloud-upload-office-365-button",
        CLOUD_ONEDRIVE_BUTTON: "cloud-upload-onedrive-button",
        CLOUD_SHAREFILE_BUTTON: "cloud-upload-sharefile-button",
        CLOUD_SHAREPOINT_BUTTON: "cloud-upload-sharepoint-button",
        CLOUD_SLACK_BUTTON: "cloud-upload-slack-button",
        CLOUD_ZENDESK_BUTTON: "cloud-upload-zendesk-button",
        CLOUD_ZOOM_BUTTON: "cloud-upload-zoom-button",
        FILE_TRANSFER_SUBMIT: "file-transfer-submit-button",
        NATIVE_UPLOAD_BUTTON: "native-upload-button",
        PDF_NO_LOAD_FILE_UPLOAD_BUTTON: "pdf-no-load-file-upload-button",
        PROCESSED_MISSING_FILES_CONTINUE_BUTTON: "processed-upload-missing-files-continue-button",
        PROCESSED_VERIFY_FILES_BUTTON: "processed-upload-verify-files-button",
    },
} as const;

type EverAttr = string | { [k: string]: EverAttr };
type RecursiveStringValuesOf<T extends EverAttr> =
    T extends Exclude<EverAttr, string> ? RecursiveStringValuesOf<T[keyof T]> : T;

/**
 * A type that includes all valid EverId values as defined in {@link EVERID}.
 */
export type EverId = RecursiveStringValuesOf<typeof EVERID>;

/**
 * Assigns the given EverId to the given {@link Element}. Throws an error if the given
 * {@link element} already has another EverId assigned to it.
 * @param element Element to add {@link everId} to.
 * @param everId EverClass to add to {@link element}.
 */
export function setEverId<E extends Element>(element: E, everId: EverId): E {
    if (element.hasAttribute(EVERID_ATTRIBUTE) && !element.matches(everIdSelector(everId))) {
        const existingId = element.getAttribute(EVERID_ATTRIBUTE);
        throw new Error(`Tried to assign id ${everId} to element with existing id ${existingId}`);
    }
    element.setAttribute(EVERID_ATTRIBUTE, everId);
    return element;
}

/**
 * Removes the current EverId (if any) from the given element.
 * @param element Element to remove {@link everId} from.
 */
export function removeEverId<E extends Element>(element: E): E {
    element.removeAttribute(EVERID_ATTRIBUTE);
    return element;
}

/**
 * Constructs a selector string for a given {@link everId}. This can be passed to anything
 * expecting a CSS selector.
 * @param everId EverId to construct a selector string for.
 */
export function everIdSelector(everId: EverId): string {
    return `[${EVERID_ATTRIBUTE}="${everId}"]`;
}

/**
 * Generates an object containing an EverId that can be spread (`...`) as props into a TSX/React
 * element or attribute object. If no {@link everId} is provided, returns an empty object.
 * @param everId EverId to assign as a prop.
 */
export function everIdProp(everId?: EverId): Record<string, EverId> {
    return everId === undefined ? {} : { [EVERID_ATTRIBUTE]: everId };
}
