import Dom = require("Everlaw/Dom");
import Is = require("Everlaw/Core/Is");
import Util = require("Everlaw/Util");
import dijit__FocusMixin = require("dijit/_FocusMixin");
import dijit__WidgetBase = require("dijit/_WidgetBase");
import dijit_focus = require("dijit/focus");
import dijit_popup = require("dijit/popup");
import aspect = require("dojo/aspect");
import declare = require("dojo/_base/declare");
import dojo_on = require("dojo/on");
import dojo_ready = require("dojo/ready");

let nextWidgetId = 1;

// The Widget hierarchy is difficult to convert to TypeScript classes. For now, most widgets will be
// untyped, but with placeholder "= any" type aliases so that widget users can be annotated
// correctly ahead of time.
abstract class Widget {
    id = nextWidgetId++;
    protected node: HTMLElement;
    private toDestroy: Util.Destroyable[] = [];
    connect(target: EventTarget, type: dojo_on.EventType, listener: EventListener) {
        // wrapper around dojo_on to make them auto-removed on destroy
        this.toDestroy.push(dojo_on(target, type, listener));
    }
    getNode() {
        return this.node;
    }
    focus() {
        this.node.focus();
    }
    blur() {
        if (this.node.contains(dijit_focus.curNode)) {
            // Dijit handles blurring using its own focus manager, which keeps track of dijit
            // widgets, and uses widget blurring to trigger onBlur calls. That's usually what we
            // want. However, in certain cases we may want to actually blur a node.
            // A child class should implement the actual blurring on its containing node. The line
            // below is commented out because this generic "blur" action might actually blur another
            // node, rather than the one you intended (if it wasn't already focused). I'm leaving
            // this here in case commenting the line out breaks behaviors; the correct thing to do
            // is invoke this function via super.blur() and then blur what you want to
            // in your child function.
            Widget.blurAll();
            //dijit_focus.curNode.blur();
        }
    }
    display(elem: any) {
        return Is.func(elem.display) ? elem.display() : elem;
    }
    shortDisplay(elem: any) {
        return Is.func(elem.shortDisplay) ? elem.shortDisplay() : this.display(elem);
    }
    /**
     * Indicates whether the widget takes care of placing itself in the DOM, thus the caller
     * (see {@link MetadataTerm#constructValueWidgets}) does not need to place the widget itself.
     */
    selfPlacedInDom() {
        return false;
    }
    registerDestroyable(d: Util.Destroyable) {
        this.toDestroy.push(d);
    }
    destroy() {
        Util.destroy(this.toDestroy);
        this.toDestroy = null;
    }
    isDestroyed(): boolean {
        return !this.toDestroy;
    }
}

module Widget {
    /**
     * A widget with a typed value.
     */
    export interface WithValue<T> extends Widget {
        getValue(): T;
    }

    /**
     * A Widget with a value that can be set. The other properties of this interface don't quite fit
     * the name here but no great ideas here for how to pull them out into separate interfaces.
     */
    export interface WithSettableValue extends WithValue<any> {
        setValue(val: any): void;
        setWidth(width: string): void;
        onBlur(): void; // happens on Enter or blur
    }

    export class NullWidget extends Widget implements WithSettableValue {
        onBlur: () => void;
        constructor() {
            super();
            this.node = Dom.span();
        }
        getValue(): void {
            return null;
        }
        setValue() {}
        setWidth(width: string) {}
    }

    export interface DijitFocusContainer {
        id: string;
        domNode: HTMLElement;
        destroy(): void;
    }

    export let DijitFocusContainer: new (params: {
        domNode: HTMLElement;
        onBlur?: () => void;
        onFocus?: () => void;
    }) => DijitFocusContainer = declare([dijit__WidgetBase, dijit__FocusMixin], {});

    // Dojo doesn't close popups properly on widget destruction, leading to an error next time a
    // popup is opened as it tries to close the old popup which no longer exists.
    // Reported as bug #17131; remove this code when fixed.
    aspect.before(dijit__WidgetBase.prototype, "destroy", function () {
        var popup = dijit_popup.getTopPopup();
        if (popup && popup.parent && this.domNode?.contains(popup.parent.domNode)) {
            dijit_popup.close(popup.widget);
        }
    });

    // Dijit focus has some big hacks to, e.g. ignore blurs that happens less than 100ms after clicks.
    // This is bad because we often programmatically issue blurs right after clicks. Thus, we need to
    // be able to disable such checks. Here, we make a dummy widget that can be focused so that instead
    // of just blurring the widget we want to blur, we are focusing on another widget, which is okay
    // with dojo.
    //
    // It's possible for the javascript to get loaded before the document is ready, so we have to wrap
    // the document.body access in dojo_ready.
    const DUMMY_BLUR_WIDGET = new DijitFocusContainer({ domNode: Dom.div() });
    Dom.hide(DUMMY_BLUR_WIDGET);
    dojo_ready(function () {
        Dom.place(DUMMY_BLUR_WIDGET, document.body);
    });

    export function blurAll() {
        DUMMY_BLUR_WIDGET.domNode.focus();
        dijit_focus._setStack([DUMMY_BLUR_WIDGET]);
    }

    export function display(elem: any) {
        return Is.func(elem.display) ? elem.display() : elem;
    }
    export function shortDisplay(elem: any) {
        return Is.func(elem.shortDisplay) ? elem.shortDisplay() : display(elem);
    }
}

export = Widget;
